<template>

  

<b-modal
        ref="modalDescripcion"
        centered
        hide-footer
        size="sm"
        title="Actualizar descripción"
        @hidden="closeModalDescription"
      >
        <div class="d-block text-center">
  
          <b-form
            autocomplete="off"
            @submit.prevent="updateDescripcion"
          >
  
            <b-row>
  
              <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      style="margin-bottom: 10px;"
                    >


                    <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                        Descripción del negocio (*):</p>

                        <b-form-textarea
                          v-model="descriptionUpdate"
                          placeholder="Ingrese la descripción de tu negocio"
                          rows="5"
                          required
                          no-resize
                          :readonly="isActiveDescripcion"
                          :state="descriptionUpdate.length <= maxChar"
                          class="char-textarea"
                          :class="descriptionUpdate.length >= maxChar ? 'text-danger' : ''"
                        />
                        <small
                          class="textarea-counter-value float-left"
                          :class="descriptionUpdate.length >= maxChar ? 'bg-danger' : ''"
                        >
                          <span class="char-count">{{ descriptionUpdate.length }}</span> / {{ maxChar }}
                        </small>

              

                    

                    </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <p
                  class="textLabel"
                  style="text-align: center;margin-bottom: 10px;"
                >
                  Ingrese su código PIN (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                    title="Código PIN de seguridad"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>
  
                <PincodeInput
                  v-model="pinDescripcion"
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                  :disabled="isActiveDescripcion"
                  required
                />
  
              </b-col>
  
              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
  
                <b-button
                  id="btn_descripcion"
                  ref="btn_descripcion"
                  type="submit"
                  variant="primary"
                  :disabled="isActiveDescripcion"
                  block
                >
  
                  Actualizar descripción
  
                </b-button>
  
              </b-col>
  
            </b-row>
          </b-form>
  
        </div>
  
      </b-modal>
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BFormTextarea, BForm
      } from 'bootstrap-vue'
      
      import PincodeInput from 'vue-pincode-input'
      
      export default {
        components: {
            PincodeInput,
            BFormTextarea,
            BForm,
          BButton,
          BRow,
          BCol,
          BCard,
  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['idLocal', 'tokenAuth', 'userId', 'description'],
        data() {
      
      
          return {
            descriptionUpdate:this.description,
            pinDescripcion:"",
  
            isActiveDescripcion:false,
            maxChar: 150,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {
   
        this.pinDescripcion="";
        this.$refs.modalDescripcion.show()
        },
        methods: {

              
closeModalDescription(){
                this.$eventBus.$emit('reiniciarModalDescripcion')
            },
  
            updateDescripcion(event) {
      event.preventDefault();


      if(Number(this.descriptionUpdate.length) > Number(this.maxChar)){

     
            this.$toast.error("El texto de la descripción sobrepasa el límite permitido de "+this.maxChar+" caracteres", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })

          }else{

          

        this.isActiveDescripcion=true;
      
      const local_json = {
           userId: this.userId, description: this.descriptionUpdate, pin: this.pinDescripcion, id: this.idLocal
          }

        



        const local_string = JSON.stringify(local_json)
  
        const dataEncripted = this.$encryptBackoffice.encrypt(local_string)
 
        document.getElementById('btn_descripcion').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando'

     
      this.$https.post('/locals/updateDescription/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        document.getElementById('btn_descripcion').innerHTML = 'Actualizar descripción';
        this.isActiveDescripcion=false;
      
    
      if (response.data.code == 200) {

        this.$eventBus.$emit('updateDescription', this.descriptionUpdate)
     

        this.pinDescripcion= "";

        this.$refs.modalDescripcion.hide();

        this.$toast.success(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        });

 

      } else {

        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

      if (response.data.code == 401) {

      

        localStorage.removeItem('userData')


        this.$router.push({ name: 'auth-login' })
      } 
      }
      }).catch(error => {
        this.$toast.error("Ocurrio un error " + error, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

          }


    },
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      